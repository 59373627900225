<template>
  <van-form @submit="onSubmit" class="login-form">
    <van-cell-group inset>
      <van-field
          v-model="tenantName"
          is-link
          readonly
          label="站点"
          placeholder="选择站点"
          @click="showPicker = true"
      />
      <van-field
          v-model="username"
          name="用户名"
          label="用户名"
          placeholder="用户名"
          :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
          v-model="password"
          type="password"
          name="密码"
          label="密码"
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
      />
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
  <van-popup v-model:show="showPicker" round position="bottom">
    <van-picker
        :columns="columns"
        @cancel="onCancel"
        @confirm="onConfirm"
    />
  </van-popup>
</template>
<script>
import store from '../../store/index.js'
import md5 from 'js-md5'
import { Toast } from "vant";
import {getRoutes, loginByUsername, getTenantList} from '../../assets/api/index'

export default {
  data() {
    return {
      username: '',
      password: '',
      columns: [],
      tenantId: '000000',
      tenantName: '长沙市',
      showPicker: false,
    }
  },
  mounted() {
    localStorage.setItem('cityCode', '4301');
    this.getTenant();
  },
  methods: {
    getTenant() {
      this.columns = [];
      getTenantList().then(res => {
        this.tenantList = res.data.data
        this.tenantList.forEach(item => {
          this.columns.push(item.tenantName)
        })
      })
    },
    onConfirm(value) {
      const cityInfo = this.tenantList.find(item => item.tenantName == value)
      this.tenantId = cityInfo.tenantId
      this.tenantName = cityInfo.tenantName
      localStorage.setItem('cityCode', `${cityInfo.city.slice(3, 7)}`);
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
      this.tenantId = '000000'
    },
    onSubmit() {
      loginByUsername(this.tenantId, '', '', this.username, md5(this.password), '', '', '').then(res => {
        const data = res.data;
        if (data.error_description) {
          Toast({
            message: data.error_description,
            type: 'error'
          })
        } else {
          console.log(data)
          localStorage.setItem('access_token', data.access_token)
          localStorage.setItem('refresh_token', data.refresh_token)
          localStorage.setItem('userId', data.user_id)
          localStorage.setItem('phone', data.phone)
          localStorage.setItem('realName', data.real_name || data.nick_name)
          getRoutes().then(res=>{
            let newRouter = res.data.data
            newRouter.push(
                {
                  alias: '售卖机数据',
                  path: '/site_count_copy',
                  id: 99999999
                }
            )
            store.commit('setRouters', newRouter)
            this.$router.push({
              path: newRouter[0].path
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="less">
.login-form {
  margin-top: 20px;
}
</style>